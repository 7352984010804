import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Marquee from "./Marquee";

const EventPage = () => {
  return (
    <Container className="py-5">
    <Marquee/>
      {/* Event Header */}
      <Row className="mb-4">
        <Col>
          <h1 className="text-center fw-bold fs-3">Sri Srinivasa Kalyana Mahotsavam 2024</h1>
          <p className="text-center text-muted fs-6">
            A grand celebration by Telugu Community Hamburg e.V., in collaboration with TTD and APNRTS.
          </p>
        </Col>
      </Row>

      {/* Compact Images Section */}
      <Row className="g-3 mb-4">
  <Col xs={12} md={4}>
    <Card className="shadow-sm">
      <Card.Img
        variant="top"
        src="/slide4.png"
        alt="Event Details"
        style={{ height: "600px", objectFit: "cover" }} // Adjusted height for better responsiveness
      />
    </Card>
  </Col>
  <Col xs={12} md={4}>
    <Card className="shadow-sm">
      <Card.Img
        variant="top"
        src="/slide5.jpg"
        alt="CM's Message in Telugu"
        style={{ height: "600px", objectFit: "cover" }} // Adjusted height for better responsiveness
      />
    </Card>
  </Col>
  <Col xs={12} md={4}>
    <Card className="shadow-sm">
      <Card.Img
        variant="top"
        src="/slide6.jpg"
        alt="CM's Message in English"
        style={{ height: "600px", objectFit: "cover" }} // Adjusted height for better responsiveness
      />
    </Card>
  </Col>
</Row>

      {/* Event Details Section */}
      <Row>
        <Col>
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <h3 className="fw-bold fs-5 mb-3">Event Details</h3>
              <div className="text-muted fs-6 mb-3">
                <p>
                  <strong>Date:</strong> <span className="text-primary">23rd November 2024</span>
                </p>
                <p>
                  <strong>Time:</strong> <span className="text-primary">08:00 - 14:00</span>
                </p>
                <p>
                  <strong>Venue:</strong>{" "}
                  <span className="text-primary">H1/H2 Kerschensteinerstraße 10, Hamburg, 21073</span>
                </p>
              </div>
              <hr />
              <p className="text-justify fs-6 mb-3">
                Telugu Community Hamburg e.V., in collaboration with <strong>Tirumala Tirupathi Devasthanam (TTD)</strong> and <strong>APNRTS</strong>,
                invites you to the divine celebration of "Sri Srinivasa Kalyanam" in Hamburg, Germany. This event offers an opportunity to witness a
                celestial wedding ceremony accompanied by intricate rituals and devotional music.
              </p>
              <p className="fs-6">
                <strong>Events and Sevas offered:</strong>
              </p>
              <ul className="ps-4 list-unstyled text-primary">
                <li>Suprabhatha Seva</li>
                <li>Thomala Seva</li>
                <li>Veda Aaseervachanam</li>
                <li>Kalyanam</li>
                <li>Visesha Kalyanam</li>
              </ul>
              <p className="fs-6 mt-3">
                We invite you to be a part of this event that fosters spiritual connection, cultural heritage, and community bonding.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Register Button */}
      <Row className="mt-4">
        <Col className="text-center">
          <Button
            variant="primary"
            className="fw-bold px-4 py-2 fs-6 rounded-pill"
            href="https://buytickets.at/telugucommunityhamburg/1447893"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register Now
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EventPage;
