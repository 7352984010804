// src/components/Marquee.js
import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Marquee.css"; // Import the CSS for the marquee effect

const Marquee = () => {
  return (
    <Row>
      <Col>
        <div className="marquee-container">
          <p className="marquee-text">
            Refer{" "}
            <a
              href="https://www.apnrts.ap.gov.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.apnrts.ap.gov.in
            </a>{" "}
            for further details
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default Marquee;
