// src/Router.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './componenets/Header'
import Home from './componenets/Home';
import AboutUs from "./componenets/AboutUs"
import Membership from './componenets/Membership'
import Gallery from './componenets/Gallery'
import Calendar from './componenets/Calendar';
import Contact from "./componenets/Contact"
import More from "./componenets/More"
import Footer from './componenets/Footer'
import Login from './componenets/Login';
import Event from './componenets/Events';
import LeadershipTeam from './componenets/LeadershipTeam';
import EventPage from './componenets/EventPage';
const AppRouter = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/more" element={<More />} />
        <Route path="/login" element={<Login />} />
        <Route path="/events" element={<Event />} />
        <Route path="/leadership" element={<LeadershipTeam />} />
        <Route path="/event/2024" element={<EventPage />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer/>
    </Router>
  );
};

export default AppRouter;
